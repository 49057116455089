import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
// import { getEmailById } from "../services/authService";

const ExportButton = ({ csvData, fileName, buttonName, isDisabled, style }) => {
  // const [infosPlayers, setInfoPlayers] = useState([]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  //   const getInfosPlayers = async () => {
  //     setInfoPlayers([]);
  //     // const promiseInfosPlayer = new Promise((resolve, reject) => {
  //     //   const response = csvData.map(async (player) => {
  //     //     let userEmail = await getEmailById(player.userId);
  //     //     setInfoPlayers((infosPlayers) => [
  //     //       ...infosPlayers,
  //     //       {
  //     //         firstName: player.firstName,
  //     //         lastName: player.lastName,
  //     //         userName: player.userName,
  //     //         poste: player.poste,
  //     //         email: userEmail.email,
  //     //       },
  //     //     ]);
  //     //   });
  //     //   resolve(response)
  //     // })

  //     // promiseInfosPlayer.then((value) => {
  //     //   console.log("value : ", value)
  //     // })

  //     // console.log("promiseInfosPlayer : ", promiseInfosPlayer);

  //     await Promise.all(
  //       csvData.map(async (player) => {
  //         let userEmail = await getEmailById(player.userId);
  //         setInfoPlayers((infosPlayers) => [
  //           ...infosPlayers,
  //           {
  //             firstName: player.firstName,
  //             lastName: player.lastName,
  //             userName: player.userName,
  //             poste: player.poste,
  //             email: userEmail.email,
  //           },
  //         ]);
  //       })
  //     ).then((result) => {
  //       console.log("result : ", result);
  //     });

  //     // const ws = XLSX.utils.json_to_sheet(infosPlayers);
  //     // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //     // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     // const data = new Blob([excelBuffer], { type: fileType });
  //     // FileSaver.saveAs(data, fileName + fileExtension);
  //   };

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      disabled={isDisabled ? true : false}
      className={style ? style : "button is-warning is-primary"}
      onClick={() => exportToCSV(csvData, fileName)}
    >
      {buttonName}
    </button>
  );
};

export default ExportButton;
