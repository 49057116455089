import React, { useState } from "react";
import "../assets/style/globalStyle.scss";
import {
  getAllEmailFromUserApple,
  getAllEmailFromUserFacebook,
  getAllEmailFromUserBNC,
} from "../services/authService";

import ExportButton from "../components/exportButton";

const AllUserEmail = () => {
  // var dataBNC = require('./BNC.json');
  // var dataFB = require('./FB.json');
  // var dataApple = require('./Apple.json');

  const [userApple, setUserApple] = useState([]);
  const [userFacebook, setUserFacebook] = useState([]);
  const [userBNC, setUserBNC] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllApple = async () => {
    setLoading(true);
    const data = await getAllEmailFromUserApple();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'utilisateur trouvé, réessayez ultérieurement.</h1>
        </div>
      );
    }

    setUserApple(data);
    setLoading(false);
  };

  const getAllFacebook = async () => {
    setLoading(true);
    const data = await getAllEmailFromUserFacebook();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'utilisateur trouvé, réessayez ultérieurement.</h1>
        </div>
      );
    }

    setUserFacebook(data);
    setLoading(false);
  };

  const getAllBNC = async () => {
    setLoading(true);
    const data = await getAllEmailFromUserBNC();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'utilisateur trouvé, réessayez ultérieurement.</h1>
        </div>
      );
    }

    setUserBNC(data);
    setLoading(false);
  };

  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <button
            disabled={loading}
            className={"button is-primary is-outlined"}
            onClick={() => getAllApple()}
          >
            Récupérer utilisateurs Apple
          </button>
        </div>
        <div className="column">
          <button
            disabled={loading}
            style={{ textAlign: "center" }}
            className={"button is-danger is-outlined"}
            onClick={() => getAllFacebook()}
          >
            Récupérer utilisateurs Facebook
          </button>
        </div>
        <div className="column">
          <button
            disabled={loading}
            style={{ textAlign: "center" }}
            className={"button is-link is-outlined"}
            onClick={() => getAllBNC()}
          >
            Récupérer utilisateurs BNC
          </button>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <ExportButton
            csvData={userApple.userProfile}
            fileName="Export Apple"
            buttonName="Export EXCEL Apple"
            isDisabled={userApple.userProfile || loading ? false : true}
            style="button is-primary is-outlined"
          />
        </div>
        <div className="column">
          <ExportButton
            csvData={userFacebook.userProfile}
            fileName="Export Facebook"
            buttonName="Export EXCEL Facebook"
            isDisabled={userFacebook.userProfile || loading ? false : true}
            style="button is-danger is-outlined"
          />
        </div>
        <div className="column">
          <ExportButton
            csvData={userBNC.userProfile}
            fileName="Export BNC"
            buttonName="Export EXCEL BNC"
            isDisabled={userBNC.userProfile || loading ? false : true}
            style="button is-link is-outlined"
          />
        </div>
      </div>
      {/* <div className="columns">
        <div className="column">
          <ExportButton
            csvData={dataApple.userProfile}
            fileName="Export Apple"
            buttonName="Export EXCEL Apple"
            isDisabled={false}
            style="button is-primary is-outlined"
          />
        </div>
        <div className="column">
          <ExportButton
            csvData={dataFB.userProfile}
            fileName="Export Facebook"
            buttonName="Export EXCEL Facebook"
            isDisabled={false}
            style="button is-danger is-outlined"
          />
        </div>
        <div className="column">
          <ExportButton
            csvData={dataBNC.userProfile}
            fileName="Export BNC"
            buttonName="Export EXCEL BNC"
            isDisabled={false}
            style="button is-link is-outlined"
          />
        </div>
      </div> */}
      {loading && <p style={{ textAlign: "center" }}>Chargement...</p>}
    </div>
  );
};

export default AllUserEmail;
